<template>
  <v-form @submit.prevent="submit">
    <v-card-text>
      <v-text-field
        v-model="form.name"
        autofocus
        label="Nome Completo"
        :disabled="loading"
        :error-messages="errors.name"
      ></v-text-field>
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="form.email"
            label="Email"
            type="email"
            :disabled="loading"
            :error-messages="errors.email"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.username"
            label="Username"
            hint="Utilizado para autenticação"
            :disabled="loading"
            :error-messages="errors.username"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.phone"
            label="Telemóvel (opcional)"
            type="phone"
            :disabled="loading"
            :error-messages="errors.phone"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn @click="reset()" text>Reset</v-btn>
      <v-btn type="submit" color="primary" :loading="loading">Adicionar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { invite } from "@/services/PortalCompanhias/insurer_users";
import ToastsMixin from "@/mixins/ToastsMixin.vue";

export default {
  mixins: [ToastsMixin],

  components: {},

  props: {
    insurerId: String,
  },

  data() {
    return {
      form: {},
      errors: {},
      loading: false,
    };
  },

  methods: {
    reset() {
      this.form = {
        name: "",
        email: "",
        username: "",
        phone: "",
      };
      this.resetErrors();
      this.loading = false;
    },

    resetErrors() {
      this.errors = {};
    },

    async submit() {
      this.resetErrors();
      this.loading = true;
      try {
        const user = await invite(this.insurerId, this.form);
        this.loading = false;
        this.$emit("submitted", user);
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
          return;
        }

        this.toastError("Houve um erro inesperado ao convidar o utilizador");
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {},
};
</script>
