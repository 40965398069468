<template>
  <div>
    <v-toolbar elevation="0">
      <v-toolbar-title>
        Utilizadores
        <v-btn @click="loadItems()" :disabled="loading" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="handleClickAddUser()" color="primary"> Adicionar </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="users"
      height="calc(100vh - 350px)"
      :items-per-page="-1"
      hide-default-footer
      fixed-header
      :loading="loading"
    >
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: 'configs.insurers_portal.users.show',
            params: { user_id: item.id },
          }"
        >
          {{ item.name }}
        </router-link>
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-chip v-if="item.is_active" small label color="green" dark>
          Ativo
        </v-chip>
        <v-chip v-else small label color="red" dark> Bloqueado </v-chip>
      </template>
    </v-data-table>

    <NewUserDialog
      ref="new_user_dialog"
      :insurer-id="id"
      @userAdded="handleUserAdded"
    />
  </div>
</template>

<script>
import { index } from "@/services/PortalCompanhias/insurer_users";
import NewUserDialog from "@/components/InsurersPortal/Users/NewUserDialog.vue";

export default {
  mixins: [],

  components: {
    NewUserDialog,
  },

  props: {
    id: String,
  },

  data() {
    return {
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Status",
          value: "is_active",
        },
      ],
      users: [],
      loading: false,
    };
  },

  methods: {
    async loadItems() {
      this.users = [];
      this.loading = true;
      this.users = await index(this.id);
      this.loading = false;
    },

    handleUserAdded() {
      this.loadItems();
    },

    handleClickAddUser() {
      this.$refs.new_user_dialog.open();
    },
  },

  computed: {},

  created() {
    this.loadItems();
  },
};
</script>
