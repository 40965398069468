<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        Adicionar utilizador
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs v-model="tab">
        <v-tab> Novo </v-tab>
        <v-tab> Existente </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <InviteUserCardForm
            @submitted="handleUserAdded"
            ref="invite_form"
            :insurer-id="insurerId"
          />
        </v-tab-item>
        <v-tab-item>
          <LinkUserCardForm
            @submitted="handleUserAdded"
            :insurer-id="insurerId"
            ref="link_form"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogsMixin from "@/mixins/DialogsMixin.vue";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import LinkUserCardForm from "./LinkUserCardForm.vue";
import InviteUserCardForm from "./InviteUserCardForm.vue";

export default {
  mixins: [DialogsMixin, ToastsMixin],

  components: {
    LinkUserCardForm,
    InviteUserCardForm,
  },

  props: {
    insurerId: String,
  },

  data() {
    return {
      tab: 0,
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.$refs.invite_form.reset();
      this.$refs.link_form.reset();
      this.tab = 0;
    },

    handleUserAdded(user) {
      this.$emit("userAdded", user);
      this.close();
      this.toast("Utilizador adicionado");
    },
  },

  computed: {},
};
</script>
