<template>
  <v-form @submit.prevent="submit">
    <v-card-text>
      <v-autocomplete
        v-model="form.user_id"
        :error-messages="errors.user_id"
        :loading="loading"
        label="Selecione o utilizador"
        autofocus
        :items="users"
        item-value="id"
        item-text="name"
        :disabled="submitting"
      >
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-avatar>
              <v-avatar color="#ccc">
                <v-icon color="white">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn type="submit" color="primary" :loading="submitting">
        Vincular
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import {
  indexAvailable,
  link,
} from "@/services/PortalCompanhias/insurer_users";
import ToastsMixin from "@/mixins/ToastsMixin";

export default {
  mixins: [ToastsMixin],

  components: {},

  props: {
    insurerId: String,
  },

  data() {
    return {
      loading: false,
      users: [],
      form: {},
      errors: {},
      submitting: false,
    };
  },

  methods: {
    reset() {
      this.form = {
        user_id: null,
      };

      this.resetErrors();
    },

    resetErrors() {
      this.errors = {};
    },

    async loadItems() {
      this.loading = true;
      this.users = [];
      try {
        this.users = await indexAvailable(this.insurerId);
      } catch (error) {
        this.toastError("Não foi possível carregar utilizadores");
      } finally {
        this.loading = false;
      }
    },

    async submit() {
      this.resetErrors();
      this.submitting = true;
      try {
        await link(this.insurerId, this.form);
        this.$emit("submitted", null);
      } catch (error) {
        if (error.response.status === 400) {
          this.toastError(error.response.data.message);
          return;
        }

        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
          return;
        }

        this.toastError("Erro inesperado");
      } finally {
        this.submitting = false;
      }
    },
  },

  computed: {},

  created() {
    this.loadItems();
  },
};
</script>
